<!-- 经费 -- 申请、审、发放 组件 -->
<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        {{ options.title }}
      </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading" id="Scrollbar">
      <div class="fullScreenMainCon">
        <el-form style="margin: 20px 0" ref="form" :rules="rulesForm" :model="form">
          <el-descriptions style="width: 100%" direction="vertical" :column="7" border>
            <el-descriptions-item
              label="单据编号1"
              v-if="options.id"
              prop="expenseNumber"
              ref="expenseNumber"
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
            >
              <el-form-item prop="expenseNumber" ref="expenseNumber">
                {{ form.expenseNumber }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              label="申请人"
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
            >
              <el-form-item prop="createName" label-width="0px">
                {{ form.createName }}
              </el-form-item></el-descriptions-item
            >
            <el-descriptions-item
              label="部门"
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
            >
              <el-form-item prop="deptId" label-width="0px">
                {{ form.deptName }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              label="申请日期"
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
            >
              <el-form-item prop="taskDate" label-width="0px">
                {{ form.taskDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
            >
              <template slot="label"> 总额度（元） </template>
              <el-form-item prop="sumQuota" label-width="0px">
                {{ form.sumQuota | applyAmount }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
            >
              <template slot="label">
                <div>审批中金额（元）</div>
                <div style="color: red" v-if="form.status != 100">包含本次单据金额</div>
              </template>
              <el-form-item prop="sumUnfinishedFlowCost" label-width="0px">
                {{ form.sumUnfinishedFlowCost | applyAmount }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
              :span="options.id ? 1 : 2"
            >
              <template slot="label"> 未冲销金额合计（元） </template>
              <el-form-item prop="sumNotCounteractCost" label-width="0px">
                {{ form.sumNotCounteractCost | applyAmount }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
            >
              <template slot="label"> 是否超额 </template>
              <el-form-item prop="" label-width="0px">{{ excess }}</el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
            >
              <template slot="label"> 超出额度（元） </template>
              <el-form-item prop="" label-width="0px">
                {{ beyondMoney | applyAmount }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                费用所属单位
              </template>
              <el-form-item prop="companyType" label-width="0px">
                <span v-if="options.disabled"> {{ form.companyType | dict(companyTypeList) }}</span>
                <Dictionary
                  v-else
                  v-model="form.companyType"
                  code="COMPANY_TYPE"
                  :clearable="false"
                  placeholder="请选择费用所属单位"
                /> </el-form-item
            ></el-descriptions-item>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                本次申请金额（元）
              </template>
              <el-form-item prop="cost" label-width="0px">
                <span v-if="options.disabled"> {{ form.cost | applyAmount }}</span>
                <template v-else>
                  <el-input
                    @blur="blurAmountFn"
                    type="number"
                    class="numrule"
                    placeholder="请输入金额"
                    v-model="form.cost"
                  >
                  </el-input>
                  <div v-show="!Number(form.cost)" style="color: red">金额不能为0</div>
                </template>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
              label="金额大写"
              :span="options.id ? 1 : 2"
              style="width: 100%"
            >
              <el-form-item label-width="0px" prop="" style="width: 100%">
                <span v-show="amount <= 0"></span>
                <span v-show="amount > 0"> {{ amount | numberParseChina }}</span>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
              :span="1"
              v-if="options.id"
              label="是否优先发放"
            >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="1"
                >是</el-radio
              >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="0"
                >否</el-radio
              >
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
              label="预支冲销/归还日期"
            >
              <template slot="label"> 预支冲销/归还日期 </template>
              <el-form-item prop="predictCounteractDate" label-width="0px">
                <span v-if="options.disabled"> {{ form.predictCounteractDate | dateFormat }}</span>
                <el-date-picker
                  v-else
                  style="width: 100%"
                  v-model="form.predictCounteractDate"
                  type="date"
                  placeholder="选择日期"
                  :clearable="false"
                  value-format="timestamp"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-descriptions-item>
            <template v-if="form.status == 100">
              <el-descriptions-item
                label="发放日期"
                labelClassName="fundsLabelClassName"
                contentClassName="fundsContentClassName"
              >
                <el-form-item prop="payedDate" label-width="0px">
                  {{ form.payedDate | dateFormat }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="fundsLabelClassName"
                contentClassName="fundsContentClassName"
                label="截止归还日期"
              >
                <el-form-item prop="counteractDeadline" label-width="0px">
                  {{ form.counteractDeadline | dateFormat }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="fundsLabelClassName"
                contentClassName="fundsContentClassName"
                label="实际归还时间"
              >
                <el-form-item prop="actualCounteractDate" label-width="0px">
                  {{ form.actualCounteractDate | dateFormat }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="fundsLabelClassName"
                contentClassName="fundsContentClassName"
                label="发放备注"
                :span="4"
              >
                <el-form-item prop="remark" label-width="0px">
                  {{ form.remark }}
                </el-form-item>
              </el-descriptions-item>
            </template>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
              :span="7"
            >
              <template slot="label">
                <span style="color: red">*</span>
                项目名称 / 公共费用 / 商务经费
              </template>
              <el-form-item prop="projectContent" label-width="0px">
                <span v-if="options.disabled"> {{ form.projectContent }}</span>
                <el-input
                  v-else
                  style="width: 100%"
                  v-model="form.projectContent"
                  placeholder="请输入项目名称 / 公共费用 / 商务经费"
                >
                </el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="fundsLabelClassName"
              contentClassName="fundsContentClassName"
              label="用途说明"
              :span="7"
            >
              <template slot="label">
                <span style="color: red">*</span>
                用途说明
              </template>
              <el-form-item prop="descriptionAbout" label-width="0px">
                <el-input
                  v-if="options.disabled"
                  type="textarea"
                  style="width: 100%"
                  :disabled="!form.isDisplayEditButton"
                  v-model="form.descriptionAbout"
                  placeholder="请输入费用预支具体说明"
                >
                </el-input>
                <el-input
                  v-else
                  type="textarea"
                  style="width: 100%"
                  v-model="form.descriptionAbout"
                  placeholder="请输入费用预支具体说明"
                >
                </el-input>
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>

        <template
          v-if="form.counteractExpenseListResp && form.counteractExpenseListResp.length > 0"
        >
          <el-table
            :data="form.counteractExpenseListResp"
            border
            id="table"
            style="margin-top: 10px"
            :row-style="{ height: '80px' }"
          >
            <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>

            <el-table-column
              prop="expenseNumber"
              align="center"
              label="单据编号"
              min-width="160"
              :show-overflow-tooltip="false"
            ></el-table-column>
            <el-table-column
              prop="createdDate"
              align="center"
              label="填报日期"
              min-width="170"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="expenseType" label="费用类型">
              <template slot-scope="scope">
                {{ scope.row.expenseType | dict(expenseTypeCounteract) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="cost"
              align="center"
              label="冲销金额"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">{{ scope.row.counteractCost | applyAmount }} </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  v-if="scope.row.expenseType === 'RETURN_EXPENDITURE'"
                  type="primary"
                  @click="fundsDetailFn(scope.row)"
                  >详情</el-button
                >
                <!-- v-if="scope.row.expenseType != 'RETURN_EXPENDITURE'" 财务归还列表移除 -->
                <el-button size="mini" v-else type="primary" @click="costDetailFn(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 冲销金额合计，本单未冲销金额 -->
          <div style="height: 20px"></div>

          <el-descriptions title="" :column="4" border>
            <el-descriptions-item label="冲销金额合计" labelStyle="width:120px">
              {{ writOffMoney | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" labelStyle="width:120px">
              {{ writOffMoney | numberParseChina }}
            </el-descriptions-item>
            <el-descriptions-item label="本单未冲销金额" labelStyle="width:120px">
              {{ thisWrittenOffMoney | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" labelStyle="width:120px">
              {{ thisWrittenOffMoney | numberParseChina }}
            </el-descriptions-item>
          </el-descriptions>
        </template>

        <OpinionArea
          ref="opinionArea"
          :procInstId="form.processInstId"
          :instInvolved="instInvolved"
          :title="'经费预支申请进度'"
        ></OpinionArea>
      </div>

      <div class="fullScreenOperation shadow">
        <template v-if="options.isApply && form.status != 10">
          <el-button class="teal_bg" type="success" @click="handleSubmit">提交</el-button>
          <el-button type="success" @click="handleSave">{{
            options.id ? '保存' : '暂存'
          }}</el-button>
        </template>
        <el-button v-if="form.isDisplayEditButton" type="warning" @click="handleSave">
          编辑
        </el-button>
        <el-button
          v-if="options.isReject && form.status == 10 && form.taskDefinitionKey != 'CASHIER'"
          type="success"
          @click="rejectFn"
          >撤回</el-button
        >
        <template v-if="options.isExamine">
          <el-button type="success" @click="handleAdopt">通过</el-button>
          <el-button type="danger" @click="handleFail">不通过</el-button>
          <SelectDialog
            v-if="form.status == 10 && permission(['FLOW_HAND_OVER'])"
            @selectDataChange="selectAuditor"
            style="display: inline-block"
          >
            <el-button slot="button" type="primary"> 转审 </el-button>
          </SelectDialog>
        </template>
        <el-button type="primary" @click="affirmCost(options.id)" v-if="options.isGrant"
          >确认发放</el-button
        >
        <el-button type="danger" v-if="options.isGrant" @click="handleFail">不通过</el-button>
        <el-button type="primary" @click="returnExpenditureFn(options.id)" v-if="options.isReturn"
          >归还</el-button
        >
        <el-button type="warning" v-if="options.isPrint || form.status > 5" @click="printFn"
          >预览打印</el-button
        >
        <el-button v-if="options.isExport || form.status > 5" type="success" @click="exportFn"
          >导出</el-button
        >
        <SelectDialog
          v-if="options.isAddAuditor && form.status == 10 && permission(['ADD_PUBLIC_AGENT'])"
          @selectDataChange="selectAddAuditor"
          style="display: inline-block"
        >
          <el-button slot="button" type="primary"> 增加待办人 </el-button>
        </SelectDialog>

        <el-button type="info" plain @click="returnFn">返回</el-button>
      </div>
    </div>

    <EditDialog width="55%" ref="IframeDlg" :isShow.sync="printDiaLog">
      <div slot="content" class="content">
        <div id="printArea">
          <div class="outer-table">{{ newDate | completeTime }}</div>
          <table border="1">
            <thead>
              <tr>
                <th colspan="2">编号：{{ form.expenseNumber }}</th>
                <th colspan="10" style="font-size: 20px">经费预支申请单</th>
              </tr>
              <tr>
                <th colspan="2">申请单位</th>
                <th colspan="10" style="font-size: 16px">
                  {{ form.companyType | dict(companyTypeList) }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td colspan="2">申请人</td>
                <td colspan="2">{{ form.createName }}</td>
                <td colspan="2">部门</td>
                <td
                  colspan="2"
                  :style="
                    form.deptName
                      ? form.deptName.length < 8
                        ? 'font-size: 16px !important;'
                        : 'font-size: 0.75rem  !important;'
                      : 'font-size: 16px !important;'
                  "
                >
                  {{ form.deptName }}
                </td>
                <td colspan="2">日期</td>
                <td colspan="2">{{ form.taskDate | dateFormat }}</td>
              </tr>
              <tr>
                <td colspan="2">预支额度（元）</td>
                <td colspan="2">{{ form.sumQuota | applyAmount }}</td>
                <td colspan="2">审批中金额（元）</td>
                <td colspan="2">{{ form.sumUnfinishedFlowCost | applyAmount }}</td>
                <td colspan="2">未冲销预支金额（元）</td>
                <td colspan="2">{{ form.sumNotCounteractCost | applyAmount }}</td>
              </tr>
              <tr>
                <td colspan="2">本次申请金额（元）</td>
                <td colspan="2">{{ form.cost | applyAmount }}</td>
                <td>是否超额</td>
                <td>{{ excess }}</td>
                <td colspan="1">超出额度（元）</td>
                <td colspan="1">{{ beyondMoney | applyAmount }}</td>
                <td colspan="2">预计冲销/归还时间</td>
                <td colspan="2">{{ form.predictCounteractDate | dateFormat }}</td>
              </tr>
              <tr>
                <td colspan="2">项目名称/公共费用/商务经费</td>
                <td
                  colspan="10"
                  :style="
                    form.projectContent
                      ? form.projectContent.length < 50
                        ? 'font-size: 16px !important;'
                        : 'font-size: 0.75rem !important;'
                      : 'font-size: 16px !important;'
                  "
                >
                  {{ form.projectContent }}
                </td>
              </tr>
              <tr class="description-about">
                <td colspan="2">用途说明</td>
                <td colspan="10">{{ form.descriptionAbout }}</td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td v-for="(item, i) in examineList" :key="i" :colspan="i == 0 ? 1 : 2">
                  {{ item.step }}
                </td>
              </tr>
              <tr>
                <td v-for="(item, i) in examineList" :key="i" :colspan="i == 0 ? 1 : 2">
                  {{ item.auditUserName }}
                </td>
              </tr>
              <tr style="height: 0px; border: 0px">
                <td style="border: 0px"></td>
                <td style="border: 0px"></td>
                <td style="border: 0px"></td>
                <td style="border: 0px"></td>
                <td style="border: 0px"></td>
                <td style="border: 0px"></td>
                <td style="border: 0px"></td>
                <td style="border: 0px"></td>
                <td style="border: 0px"></td>
                <td style="border: 0px"></td>
                <td style="border: 0px"></td>
                <td style="border: 0px"></td>
              </tr>
            </tfoot>
          </table>
          <div class="outer-table">1/1</div>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" v-print="print">打印</el-button>
        <el-button type="info" @click="printDiaLog = false">返回</el-button>
      </div>
    </EditDialog>

    <EditDialog :isShow.sync="isAdopt" :isReturn="true" @closeFn="cancel">
      <template v-slot:title>
        <span style="color: #409eff">经费预支通过审批</span>
      </template>
      <template v-slot:content>
        <el-form class="column3" label-width="6em" ref="ruleForm" :model="examineForm">
          <el-form-item label="审核意见" style="width: 100%">
            <el-input v-model="examineForm.opinion" type="textarea" placeholder="通过"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="success" @click="submit">审批通过</el-button>
        <el-button type="warning" plain @click="cancel">取消</el-button>
      </template>
    </EditDialog>
    <EditDialog :isShow.sync="isFail" :isReturn="true" @closeFn="cancel">
      <template v-slot:title>
        <span style="color: #409eff">经费预支不通过审批</span>
      </template>
      <template v-slot:content>
        <el-form
          class="column3"
          label-width="6em"
          ref="ruleForm"
          :model="examineForm"
          :rules="rules"
        >
          <el-form-item label="审核意见" prop="opinion" style="width: 100%">
            <el-input
              v-model="examineForm.opinion"
              type="textarea"
              placeholder="请输入原因"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="danger" @click="submit">审批不通过 </el-button>
        <el-button type="warning" plain @click="cancel">取消</el-button>
      </template>
    </EditDialog>

    <!-- 财务确认发放弹出框 -->
    <EditDialog :width="'40%'" :isShow.sync="passDialogFormVisible" @submit="passSubmit">
      <div slot="title" class="title">确认发放</div>
      <div slot="content" class="content">
        <el-form :model="pass_form" label-width="100px">
          <el-form-item label="发放时间" prop="payedDate">
            <el-date-picker
              v-model="pass_form.payedDate"
              type="date"
              placeholder="选择日期"
              :clearable="false"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="备注" prop="remark" style="margin-top: 10px">
            <el-input v-model="pass_form.remark" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>
    <!-- 财务归还弹出框 -->
    <EditDialog :width="'40%'" :isShow.sync="isReturnExpenditure" @submit="returnExpenditureSubmit">
      <div slot="title" class="title">归还</div>
      <div slot="content" class="content">
        <el-form :model="returnExpenditure" label-width="100px">
          <el-form-item label="冲销金额" prop="counteractCost">
            <el-input
              @blur="blurReturnExpenditureFn"
              type="number"
              class="numrule"
              placeholder="请输入金额"
              v-model="returnExpenditure.counteractCost"
            >
            </el-input>
            <div v-show="!Number(returnExpenditure.counteractCost)" style="color: red">
              金额不能为0
            </div>
          </el-form-item>
          <el-form-item label="归还时间" prop="counteractDate" style="margin-top: 10px">
            <el-date-picker
              v-model="returnExpenditure.counteractDate"
              type="date"
              placeholder="选择日期"
              :clearable="false"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="备注" prop="remark" style="margin-top: 10px">
            <el-input v-model="returnExpenditure.remark" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>
    <!-- 报销详情 -->
    <CostDetails
      @CostDetailsFn="CostDetailsFn"
      :costDetailDialog_show.sync="costDetailDialog"
      :options="costOptions"
    ></CostDetails>
    <!-- 提交意见 -->
    <SubmitOpinionDialog
      v-if="submitDialog"
      :isShow.sync="submitDialog"
      @offsetCancel="submitDialog = false"
      @submitOpinionFn="submitOpinionFn"
    />
  </div>
</template>

<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    CostDetails: () => import('@/components/funds/cost-details.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
    SubmitOpinionDialog: () => import('@/components/submit-opinion-dialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {
          title: '', //面包屑
          isExamine: false, //审核
          isExport: false, //导出
          isPrint: false, //打印
          disabled: false, //编辑
          isApply: false, //新增
          isReject: false, //撤回
          isGrant: false, //发放
          isReturn: false, //归还
          isAddAuditor: false, //新增待办人
        }
      },
    },
  },
  data() {
    return {
      showEditDialog: false, // 组件 隐藏 或 显示
      loading: false, // 组件 加载
      form: {
        // 申请 表单数据
        deptId: JSON.parse(localStorage.getItem('userInfo')).deptIdList[0],
        deptName: JSON.parse(localStorage.getItem('userInfo')).deptNames.split(',')[0],
        createName: JSON.parse(localStorage.getItem('userInfo')).userName,
        createBy: JSON.parse(localStorage.getItem('userInfo')).staffId,
        taskDate: new Date().getTime(),
        predictCounteractDate: null,
        projectContent: null,
        descriptionAbout: null,
        companyType: null,
        cost: null,
      },
      pickerOptions: {
        //预支冲销/归还日期范围限制 当天到30天（自然日）
        disabledDate(time) {
          return time.getTime() < Date.now() || time.getTime() > Date.now() + 30 * 8.64e7
        },
      },
      examineForm: {
        // 审批 表单数据
        opinion: '',
        status: 0,
      },
      rules: {
        // 审核表单 验证
        opinion: [{ required: true, message: '请输入审核意见', trigger: 'blur' }],
      },
      rulesForm: {
        // 单据表单 验证
        companyType: [
          {
            required: true,
            message: '请填写费用所属单位',
            trigger: ['blur', 'change'],
          },
        ],
        cost: [
          {
            required: true,
            message: '请输入金额',
            trigger: 'blur',
          },
        ],
        projectContent: [
          {
            required: true,
            message: '请输入项目名称/公共费用/商务经费',
            trigger: 'blur',
          },
        ],
        descriptionAbout: [
          {
            required: true,
            message: '请输入费用预支具体说明',
            trigger: 'blur',
          },
        ],
        // predictCounteractDate: [
        //   {
        //     required: true,
        //     message: '请选择预支冲销/归还日期',
        //     trigger: ['blur', 'change'],
        //   },
        // ],
      },
      filepath: process.env.VUE_APP_FILEPATH, //文件上传
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'JFYZtype',
      },
      type: 'JFYZ',
      isAdopt: false, //审核通过
      isFail: false, //审核不通过
      companyTypeList: [], //费用所属单位
      oldSumUnfinishedFlowCost: 0, //原始审批中金额
      instInvolved: {}, //当前进度处理人数组
      passDialogFormVisible: false, //发放
      pass_form: {}, //发放
      print: {
        //打印
        id: 'printArea',
        popTitle: '', // 打印配置页上方的标题
        type: 'html', //打印类型是html
        scanStyles: false,
        targetStyles: ['*'],
      },
      examineList: [], //打印
      printDiaLog: false, //打印
      newDate: null, //打印
      costOptions: {}, //预支经费中报销
      costDetailDialog: false, //预支经费中报销
      expenseTypeCounteract: [], //费用类型-冲销
      returnExpenditure: {}, //归还
      isReturnExpenditure: false, //归还
      submitDialog: false, //提交意见弹窗
    }
  },
  inject: ['re'],
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler: function (newVal) {
        if (newVal.id) {
          this.getDetails()
        } else {
          this.$api.funds
            .onlyGroupQuota()
            .then(res => {
              if (res.data) {
                this.form = {
                  ...this.form,
                  ...res.data,
                }
                this.oldSumUnfinishedFlowCost = res.data.sumUnfinishedFlowCost
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
    },
    'form.cost': {
      immediate: true,
      handler: function (newVal) {
        this.form.sumUnfinishedFlowCost = Number(newVal) + this.oldSumUnfinishedFlowCost
      },
    },
    printDiaLog: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            let Styles = document.querySelectorAll('.detail')
            var userAgent = navigator.userAgent.toLowerCase()
            let str = userAgent.split('/')
            str = str[3].split(' ')
            str = str[0].split('.')
            Styles.forEach(v => {
              if (userAgent.includes('edg')) {
                v.style.setProperty('--height', '72px')
              } else if (userAgent.includes('chrome')) {
                if (str[0] < 110) {
                  v.style.setProperty('--height', '72px')
                } else {
                  v.style.setProperty('--height', '72px')
                }
              } else {
                v.style.setProperty('--height', '72px')
              }
            })
          })
        }
      },
    },
  },
  created() {
    // 费用所属单位
    this.$api.dict
      .listSysDictData('COMPANY_TYPE')
      .then(res => {
        this.companyTypeList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('EXPENSE_TYPE_COUNTERACT', true)
      .then(res => {
        this.expenseTypeCounteract = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  computed: {
    amount() {
      let num = 0
      if (this.form.cost) {
        num = Number(this.form.cost)
      }
      num = num.toFixed(2)
      return num
    },
    // 超额
    excess() {
      const Quota = Number(this.form.sumQuota)
      const UnfinishedFlow = Number(this.form.sumUnfinishedFlowCost)
      const NotCounteract = Number(this.form.sumNotCounteractCost)
      if (UnfinishedFlow + NotCounteract > Quota) {
        return '是'
      } else {
        return '否'
      }
    },
    // 超出金额
    beyondMoney() {
      let num = 0
      const Quota = Number(this.form.sumQuota)
      const UnfinishedFlow = Number(this.form.sumUnfinishedFlowCost)
      const NotCounteract = Number(this.form.sumNotCounteractCost)
      num = UnfinishedFlow + NotCounteract - Quota
      if (num > 0) {
        return num
      } else {
        return 0
      }
    },
    // 审核金额
    examineMoney() {
      let num = 0
      const cost = Number(this.form.cost)
      num = this.oldSumUnfinishedFlowCost + cost
      return num
    },
    // 冲销金额合计
    writOffMoney() {
      let num = 0
      if (this.form.counteractExpenseListResp && this.form.counteractExpenseListResp.length > 0) {
        this.form.counteractExpenseListResp.forEach(v => {
          num += Number(v.counteractCost)
        })
      }
      return num
    },
    // 本单未冲销金额
    thisWrittenOffMoney() {
      let num = 0
      num = this.form.cost - this.writOffMoney
      if (num > 0) {
        return num
      } else {
        return 0
      }
    },
  },
  methods: {
    // 新增审核人
    selectAddAuditor(row) {
      this.$api.addAuditor
        .addTaskSetAssignee({
          userId: row.id,
          processInstId: this.form.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 转审
    selectAuditor(row) {
      this.$api.addAuditor
        .taskSetAssignee({
          userId: row.id,
          processInstId: this.form.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 明细归还详情
    CostDetailsFn() {
      this.costOptions = {}
      this.costDetailDialog = false
    },
    // 明细归还详情
    fundsDetailFn(row) {
      this.costOptions = row.deepClone()
      this.costDetailDialog = true
    },
    // 预支经费归还
    returnExpenditureSubmit() {
      if (!this.returnExpenditure.counteractCost) return
      this.loading = true
      this.$api.funds
        .returnExpenditure(this.returnExpenditure)
        .then(res => {
          this.loading = false
          this.$message({
            type: 'success',
            message: '归还成功!',
          })
          this.isReturnExpenditure = false
          this.loading = false
          this.re()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.isReturnExpenditure = false
        })
    },
    // 预支经费归还
    blurReturnExpenditureFn() {
      if (this.returnExpenditure.counteractCost < 0) {
        this.returnExpenditure.counteractCost = 0
      }
      this.returnExpenditure.counteractCost = Number(this.returnExpenditure.counteractCost).toFixed(
        2
      )
    },
    // 预支经费归还
    returnExpenditureFn(id) {
      this.returnExpenditure = {
        id: id,
        counteractCost: null,
        counteractDate: new Date().getTime(),
        remark: null,
      }
      this.isReturnExpenditure = true
    },
    // 预支经费中报销
    CostDetailsFn() {
      this.costOptions = {}
      this.costDetailDialog = false
    },
    // 预支经费中报销
    costDetailFn(row) {
      this.costOptions = row.deepClone()
      this.costDetailDialog = true
    },
    // 打印
    async printFn() {
      this.newDate = Date.now()
      this.$nextTick(async () => {
        this.examineList = []
        if (this.$refs.opinionArea && this.$refs.opinionArea.opinionRecordList.length > 1) {
          let examineArr = []
          const arr = this.$refs.opinionArea.opinionRecordList
          await arr.forEach((v, i) => {
            if (v.isCheckPass == 1) {
              v.id = i + Date.now()
              examineArr.push(v)
            }
          })
          let arr2 = examineArr.reduce((filteredArr, item) => {
            const index = filteredArr.findIndex(el => el.step === item.step)
            if (index !== -1) {
              const existingItem = filteredArr[index]
              if (item.auditDate > existingItem.auditDate) {
                filteredArr[index] = item
              }
            } else {
              filteredArr.push(item)
            }
            return filteredArr
          }, [])
          this.examineList = arr2.reverse()
          let num = 0
          if (this.examineList.length === 0) {
            num = (this.examineList.length % 7) + 1
          } else {
            num = this.examineList.length % 7
          }
          while (num < 7 && num !== 0) {
            this.examineList.push({
              id: Date.now() + num,
              step: '',
              auditUserName: '',
            })
            num = this.examineList.length % 7
          }

          this.printDiaLog = true
        } else {
          // this.$message.warning('该预支单审核流程尚未走完')
          let num = (this.examineList.length % 7) + 1
          while (num < 7 && num !== 0) {
            this.examineList.push({
              id: Date.now() + num,
              step: '',
              auditUserName: '',
            })
            num = this.examineList.length % 7
          }
          this.printDiaLog = true
        }
      })
    },
    // 发放
    affirmCost(id) {
      this.pass_form = {
        payedDate: new Date().getTime(),
        remark: '',
      }
      this.pass_form.id = id
      this.passDialogFormVisible = true
    },
    passSubmit() {
      this.loading = true
      this.$api.funds
        .affirmApply(this.pass_form)
        .then(res => {
          this.loading = false
          this.$message({
            type: 'success',
            message: '操作成功!',
          })
          this.passDialogFormVisible = false

          this.loading = false
          this.re()
        })
        .catch(err => {
          console.log(err)
          this.passDialogFormVisible = false
          this.loading = false
        })
    },
    // 撤回
    rejectFn() {
      this.loading = true
      this.$api.funds
        .reject({
          id: this.form.id,
        })
        .then(res => {
          this.$message({
            message: '撤回成功!',
            type: 'success',
          })
          this.loading = false
          this.returnFn()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 导出
    exportFn() {
      let time = new Date(this.$refs.opinionArea?.opinionRecordList[0].auditDate).format(
        'yyyy-MM-dd'
      )
      let type = this.$refs.opinionArea?.opinionRecordList[0].type
      let str = `${type}&${time}&${this.form.createName}&${this.amount}元`
      this.$api.funds
        .exporting(this.form.id)
        .then(res => {
          const fileName = `${str}.xlsx`
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
            this.$message({
              message: '导出成功!',
              type: 'success',
            })
            document.body.removeChild(link) //下载完成移除元素
          }
        })
        .catch(e => {})
    },
    // 返回
    returnFn() {
      this.re()
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.form = {}
    },

    /** 加载 "经费申请" 详情数据 **/
    async getDetails() {
      this.loading = true
      this.$api.funds
        .selectDetailById(this.options.id)
        .then(res => {
          if (res.data) {
            this.form = res.data
            this.instInvolved = {
              instInvolvedUserName: res.data.instInvolvedUserName
                ? res.data.instInvolvedUserName
                : [],
              status: res.data.status,
            }
            this.oldSumUnfinishedFlowCost = res.data.sumUnfinishedFlowCost
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /** 失去焦点触发 "申请金额" 文本框 **/
    blurAmountFn() {
      if (this.form.cost < 0) {
        this.form.cost = 0
      }
      this.form.cost = Number(this.form.cost).toFixed(2)
    },

    /** 点击 "提交" 按钮 **/
    handleSubmit() {
      if (!Number(this.form.cost)) return
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.loading = true
          this.submitDialog = true
          this.loading = false
        } else {
          this.scrollView(obj)
        }
      })
    },
    // 提交意见
    submitOpinionFn(opinion) {
      this.loading = true
      // 状态为 5并且有流程ID 是驳回，有流程ID并且状态为0 是撤回
      if (
        (this.form.processInstId && this.form.status == '5') ||
        (this.form.processInstId && this.form.status == 0)
      ) {
        this.$api.funds
          .resubmitByReject({ ...this.form, opinion })
          .then(res => {
            this.loading = false
            this.$message.success('操作成功！')
            this.returnFn()
          })
          .catch(err => {
            this.loading = false
            console.log('错误：', err)
          })
      } else {
        this.$api.funds
          .startProcess({ ...this.form, opinion })
          .then(res => {
            this.$message({
              message: '提交成功',
              type: 'success',
            })
            this.loading = false
            this.returnFn()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
      this.submitDialog = false
    },

    /** 点击 "暂存" 按钮 **/
    handleSave() {
      this.loading = true
      if (this.options.id) {
        this.$api.funds
          .undeterminedEdit(this.form)
          .then(res => {
            this.$message({
              message: '编辑成功',
              type: 'success',
            })
            this.loading = false
            this.returnFn()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      } else {
        this.$api.funds
          .tempAdd(this.form)
          .then(res => {
            this.$message({
              message: '暂存成功',
              type: 'success',
            })
            this.loading = false
            this.returnFn()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
    },

    /** 点击 "通过" 按钮 **/
    handleAdopt() {
      this.examineForm.status = 1
      this.isAdopt = true
    },

    /** 点击 "不通过" 按钮 **/
    handleFail() {
      this.examineForm.status = 2
      this.isFail = true
    },
    /** 审核按钮 **/
    submit() {
      const obj = {
        id: this.form.id,
        opinion: this.examineForm.opinion,
      }
      if (this.examineForm.status === 1) {
        obj.opinion = obj.opinion ? obj.opinion : '通过'
        obj.processInstId = this.form.processInstId
        this.loading = true
        // this.$api.funds
        //   .disposeTask(obj)
        //   .then(res => {
        //     this.$message({
        //       message: '操作成功',
        //       type: 'success',
        //     })
        //     this.loading = false
        //     this.isAdopt = false
        //     this.returnFn()
        //   })
        //   .catch(err => {
        //     console.log(err)
        //     this.loading = false
        //     this.isAdopt = false
        //   })
        this.$api.addAuditor
          .disposeTask(obj)
          .then(res => {
            this.$message({
              message: '操作成功',
              type: 'success',
            })
            this.loading = false
            this.isAdopt = false
            this.returnFn()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
            this.isAdopt = false
          })
      } else {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.loading = true
            this.$api.funds
              .approvalReject(obj)
              .then(res => {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                })

                this.loading = false
                this.isFail = false
                this.returnFn()
              })
              .catch(err => {
                console.log(err)
                this.loading = false
                this.isFail = false
              })
            // this.$api.addAuditor
            //   .disposeTask(obj)
            //   .then(res => {
            //     this.$message({
            //       message: '操作成功',
            //       type: 'success',
            //     })

            //     this.loading = false
            //     this.isFail = false
            //     this.returnFn()
            //   })
            //   .catch(err => {
            //     console.log(err)
            //     this.loading = false
            //     this.isFail = false
            //   })
          }
        })
      }
    },
    // 关闭弹窗
    cancel() {
      this.isAdopt = false
      this.isFail = false
      this.examineForm = {
        opinion: '',
        status: 0,
        id: null,
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
.color_r {
  color: #f00;
}

.el-form-item {
  margin-bottom: 0px !important;
}
#printArea {
  word-break: break-all !important;
  font-family: '微软雅黑';
  width: 1000px;
  .outer-table {
    display: none;
    text-align: right;
    font-size: 16px !important;
  }
  table {
    font-size: 12px !important;
    // .detail {
    //   text-align: center;
    //   height: var(--height); //75
    //   line-height: 1;
    // }
    width: 100%;
    text-align: center;
    thead {
      tr {
        height: 52px;
      }
    }
    tbody {
      td {
        height: 52px;
      }
      .description-about {
        height: 115px;
      }
    }
    tfoot {
      text-align: center;

      tr {
        height: 52px;
      }
      td {
        width: 165px;
      }
    }
  }
}
@media print {
  @page {
    margin: 8mm 10mm;
    size: landscape; //横向
  }
  #printArea {
    .outer-table {
      display: block;
    }
    tbody {
      .description-about {
        height: 220px !important;
      }
    }
  }
}
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.fundsLabelClassName {
  width: 14.2%;
}
/deep/.fundsContentClassName {
  height: 75px !important;
}
</style>
